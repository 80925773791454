// SubscribeBlock.vue

<template>
  <div>{{ lat }} - {{ lon }}</div>
</template>

<script>
export default {
  data () {
    return {
      lat: 0,
      lon: 0
    }
  },
  methods: {
    geolocation () {
      navigator.geolocation.getCurrentPosition(this.buildUrl, this.geoError)
    },
    buildUrl (position) {
      this.lat = position.coords.latitude
      this.lon = position.coords.longitude
    },
    geoError (error) {
      this.lat = 0
      this.lon = 0
      console.log(error)
    }
  },
  beforeMount () {
    this.geolocation()
  }
}
</script>

<style>
</style>
